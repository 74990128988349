import {Component} from '@angular/core';
import {ViewportScroller} from "@angular/common";

import {FirebaseService} from "./core/services/firebase.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    private _authService: FirebaseService,
    private _viewportScroller: ViewportScroller
  ) {
    this._authService.signInAnonymously();
  }

  onActivate(): void {
    setTimeout(() => {
      this._viewportScroller.scrollToPosition([0, 0]);
    }, 250)
  }

}
