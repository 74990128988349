import {environment} from "src/environments/environment";
import {Injectable} from "@angular/core";
import {
  signInAnonymously,
  Auth,
  browserSessionPersistence,
  onAuthStateChanged,
  onIdTokenChanged
} from "@angular/fire/auth";
import {doc, getDoc, getFirestore} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private auth: Auth) {
    this.auth.setPersistence(browserSessionPersistence);
    auth.tenantId = environment.tenantId;
  }

  async signInAnonymously() {
    await signInAnonymously(this.auth);
    this.getFirebaseClientId();
  }

  getToken(refreshToken: boolean = false) {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          user.getIdToken(refreshToken).then((token) => resolve(token)).catch(() => resolve(''));
        } else resolve('');
      })
    })
  }

  getFirebaseClientId() {
    const docRef = doc(getFirestore(), 'config', 'client-id');
    const docSnap = getDoc(docRef);
    docSnap.then(async (document) => {
      const client = document.data() ?? {};
      localStorage.setItem('client_id', client['client-id']);
    });
  }

}
