import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {catchError, from, Observable, switchMap, throwError} from 'rxjs';
import {FirebaseService} from "../services/firebase.service";
import {environment} from "../../../environments/environment";

declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (idOrContainer: string | HTMLElement, options: any) => string;
      reset: (widgetIdOrContainer: string | HTMLElement) => void;
      getResponse: (widgetIdOrContainer: string | HTMLElement) => string | undefined;
      remove: (widgetIdOrContainer: string | HTMLElement) => void;
    };
  }
}


@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    private firebase: FirebaseService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const client_id = localStorage.getItem('client_id')?.toString() ?? '';
    return from(this.firebase.getToken()).pipe(switchMap(token_firebase => {

      const action = request.headers.get('action') ?? 'action';
      const promise = new Promise((resolve, reject) => {
        window.turnstile.render('#captcha_turnstile', {
          sitekey: environment.recaptcha.publicKey,
          theme: 'light',
          action,
          callback: function (token: string) {
            resolve(token)
          }
        })
      })

      return from(promise).pipe(switchMap(recaptchaToken => {
        const headers = request.clone(
          {
            setHeaders: {
              Authorization: `Bearer ${token_firebase}`,
              'X-Recaptcha-Token': recaptchaToken as string
            },
            setParams: {
              'client-id': client_id
            }
          }
        );
        return next.handle(headers).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 || err.status === 403) {
              return from(this.firebase.getToken(true)).pipe(switchMap(token_firebase_updated => {
                return from(promise).pipe(switchMap(recaptchaToken => {
                  const headers = request.clone(
                    {
                      setHeaders: {
                        Authorization: `Bearer ${token_firebase}`,
                        'X-Recaptcha-Token': recaptchaToken as string
                      },
                      setParams: {
                        'client-id': client_id
                      }
                    }
                  );
                  return next.handle(headers);
                }))
              }))
            } else {
              return throwError(() => err)
            }
          })
        );
      }))
    }))
  }
}

