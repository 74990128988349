import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'booking', pathMatch: 'full' },
  { path: 'booking',
    loadChildren: () => import('./modules/booking-module/booking-module.module').then(m => m.BookingModuleModule)
  },
  {
    path: 'circuitoW',
    loadChildren: () => import('./modules/circuito-w/circuito-w.module').then(m => m.CircuitoWModule)
  },
  {
    path: 'macizo-paine',
    loadChildren: () => import('./modules/macizo-paine/macizo-paine.module').then(m => m.MacizoPaineModule)
  },
  {
    path: 'puerto-natales',
    loadChildren: () => import('./modules/puerto-natales/puerto-natales.module').then(m => m.PuertoNatalesModule)
  },
  {
    path: 'resumen',
    loadChildren: () => import('./modules/resumen/resumen.module').then(m => m.ResumenModule)
  },
  {
    path: 'pago',
    loadChildren: () => import('./modules/pago/pago.module').then(m => m.PagoModule)
  },
  {
    path:'datos-personales',
    loadChildren: () => import('./modules/personal-data/personal-data.module').then(m => m.PersonalDataModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
