import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {
  AngularFireRemoteConfig,
  AngularFireRemoteConfigModule
} from "@angular/fire/compat/remote-config";
import {TranslateModule} from '@ngx-translate/core';
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getApp, initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {AngularFireModule} from "@angular/fire/compat";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Interceptor} from './core/interceptors/interceptor';
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth(getApp())),
    provideFirestore(() => getFirestore(getApp())),
    provideStorage(() => getStorage(getApp())),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireRemoteConfigModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
  }
}
