export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyA9Na4ypocEJDSbq8_h7jH9-hJmyHQ3zOQ",
    authDomain: "vertice-preprod.firebaseapp.com",
    projectId: "vertice-preprod",
    storageBucket: "dev.storage.vertice.travel",
    messagingSenderId: "332526162107",
    appId: "1:332526162107:web:f1b7f271e6ad34700e999e",
    measurementId: "G-VS1EWN2SWE"
  },
  tenantId: null,
  // baseURL: "http://localhost:8080/api/v1/",
  baseURL: "https://dev.api.booking.vertice.travel/api/v1/",
  endpoints: {
    availability: "availability",
    pre_booking: "pre-booking",
    bookings: "bookings",
    cancel: "cancel",
    get_booking: "get-booking",
    hotel: "hotel",
    fns: "fns",
    paypal: "paypal",
    webpay: "webpay",
    create_checkout: "create-checkout",
    set_products: "set-products",
    set_personal_data: "set-personal-data",
    success: "success",
    download_summary: "download-summary",
    booking_complete: "get-booking-complete"
  },
  recaptcha: {
    // publicKey:"0x4AAAAAAACEEuc9poiiONKN"
    publicKey: "0x4AAAAAAACKFC2ZIhVUxIYx"
  },
  storage: {
    policies: 'public/Commercial policies'
  }
};
